<template>
    <div>
        <Loading v-if="processing" />
        <div v-if="rcode" class=" grid grid-cols-1">

            <div class="place-self-center">
                <h1 class="pl-2
              justify-self-center
              font-boldcom
              logo">Your - Rcode</h1>
            </div>

            <div class="place-self-center mt-4">
                <span class="rap-btn cursor-pointer">{{ rcode }}</span>
            </div>
        </div>

        <div v-else class=" grid grid-cols-1">

            <div class="place-self-center">
                <h1 class="pl-2
              justify-self-center
              font-boldcom
              logo">Generate - Rcode</h1>
            </div>

            <div class="place-self-center">
                <button @click="generatecode" class="rap-btn cursor-pointer mt-5">Generate</button>
            </div>
        </div>
    </div>
</template>

<script>
import API from "../../../api/http";
import Loading from '../../Shared/Loading'

export default {
    name: 'GenerateRcode',
    data() {
        return {
            url: "generate-rcode",
            processing: false,
            profileData: null,
            rcode: null,
        }
    },
    components: {
        Loading,
    },
    methods: {
        generatecode() {
            this.processing = true;
            API.post(this.url).then((response) => {
                window.location.replace(response.data.url);
            });
        },
    },
    computed: {
        user() {
            return this.$store.getters["User/user"];
        },
    },
    created() {
        this.profileData = { ...this.user };
        this.rcode = this.profileData.rcode;

    }
}

</script>